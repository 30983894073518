import React from "react";
import { benefitsOfTheSolution } from "../assets";

function Benefits() {
  return (
    <div className={`bg-[#F0EFEF]`}>
      {/* <h3 className='mb-14 font-bold font-open_sans text-[40px] text-center leading-[54.47px]'>
        Benefits Of The Solution
      </h3> */}
      <div className="flex items-center justify-center">
        <div>
          <img src={benefitsOfTheSolution} alt="img1" />
        </div>
      </div>
    </div>
  );
}

export default Benefits;
