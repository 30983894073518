import React from "react";
import { vector2 } from "../assets";

function Vector2() {
  return (
    <div className={`bg-white px-8 md:px-20 py-6 xs:py-14`}>
      <div className="grid grid-cols-1 sm:grid-cols-2">
        <div className="items-center justify-start hidden col-span-1 sm:flex">
          <img src={vector2} alt="img1" />
        </div>
        <div className="flex items-center justify-center col-span-1">
          <p className="text-[20px] xs:text-[25px] tracking-wide font-poppins xs:px-10 md:px-28">
            <span className="font-bold">
              With AI at its core, iVerifai significantly speeds up the invoice
              verification process,{" "}
            </span>
            allowing your team to focus on more strategic tasks. Plus, the
            AI-powered system adheres to stringent data protection regulations,
            safeguarding your sensitive financial information.
          </p>
        </div>
      </div>
      {/* <div className='flex flex-col items-center justify-center pt-8 xs:pt-24'>
        <div className='py-2 sm:py-6 sm:px-10'>
          <h4 className='text-[24px] font-semibold font-poppins text-center xs:text-[28px] lg:text-[37px]'>
            Designed specifically to manage
          </h4>
          <h4 className='text-[24px] font-semibold font-poppins text-center xs:text-[28px] lg:text-[37px]'>
            shipments and service providers in the shipping industry
          </h4>
        </div>
        <div className='bg-[#002B9A] w-[150px] sm:w-[500px] h-[2px] sm:h-[6px]'></div>
        <div className='sm:py-6 py-2 sm:w-[900px] text-center'>
          <p className='text-[20px] xs:text-[25px] font-poppins'>
            We streamline your verification process, ensuring every transaction aligns flawlessly with your rate cards.
          </p>
        </div>
      </div> */}
    </div>
  );
}

export default Vector2;
