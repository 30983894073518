import React from "react";
import { vector1 } from "../assets";

function Vector1() {
  return (
    <div className={`bg-[#E3E3E3] px-10 py-8 md:px-20 xs:py-14`}>
      <div className="grid grid-cols-1 sm:grid-cols-2">
        <div className="flex items-center justify-center col-span-1">
          <p className="text-[20px] xs:text-[25px] tracking-wide font-poppins xs:px-10 md:px-28">
            <span className="font-bold">
              The AI algorithms automatically extract and validate invoice data
              against your contracts and rate cards
            </span>
            , to identify discrepancies, under-charges, overvharges, and missing
            data to provide real-time alerts and suggestions for corrections.
          </p>
        </div>
        <div className="items-center justify-end hidden col-span-1 sm:flex">
          <img src={vector1} alt="img1" />
        </div>
      </div>
    </div>
  );
}

export default Vector1;
