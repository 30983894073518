import React from "react";
import styles from "../style";
import { hero } from "../assets";

function Hero() {
  return (
    <section
      id="home"
      // style={{
      //   backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70)), url(${heroBg})`,
      //   backgroundRepeat: 'no-repeat',
      //   backgroundSize: 'cover',
      //   backgroundPosition: '50% 50%',
      // }}
      className={`flex bg-white lg:flex-row flex-col h-[860px] xs:h-[780px] sm:h-[650px] md:h-[600px] lg:h-[750px]`}
    >
      <div
        className={`flex-1 ${styles.flexStart} flex-col xl:px-0 md:px-36 sm:px-24 xs:px-8 px-6`}
      >
        <div className="flex flex-col items-center justify-between w-full gap-6 xs:gap-12 lg:flex-row">
          <div className="flex flex-col w-full lg:w-[60%] gap-[10px]">
            <div className="flex gap-3">
              <div className="hidden w-2 bg-primary xs:block"></div>
              <div>
                <h1 className="font-semibold font-poppins text-[24px] xs:text-[28px] lg:text-[37px] lg:leading-[49.77px] xs:leading-[44px] leading-[35px]">
                  Powering up <br /> invoice verification with
                  <span className="text-primary"> iVerifai</span>
                </h1>
              </div>
            </div>
            <div className="xs:ml-5 w-[95%] text-primary">
              <p className="font-poppins">
                • Tired of manual line by line checking of invoices?
              </p>
              <p className="font-poppins">
                • At risk of overpaying against complex contract rates and
                non-standard charges?
              </p>
              <p className="font-poppins">
                • Frequent issues with payments to suppliers?
              </p>
              <p className="font-poppins">
                • Paying for costly manual labour to verify charges against
                contracts?
              </p>
            </div>
            <div className="xs:ml-5 w-[86%] font-bold">
              <p className="font-poppins">
                Automate this process and be faster, accurate and more efficient
                by uploading your contracts, rate sheets and invoices.
              </p>
            </div>
            <div className="xs:ml-5 w-[86%] font-bold">
              <p className="font-poppins">
                Let iVerifai be your virtual auditor for faster reconciliation
                and better collaboration with your suppliers and service
                providers.
              </p>
            </div>
            <div className="xs:ml-5 w-[86%]">
              <p className="xs:text-[25px] text-[20px] tracking-wider font-poppins">
                Efficiency, accuracy and peace of mind – that's what iVerifai
                brings to your
                <span className="italic font-bold">
                  {" "}
                  invoice verification process.
                </span>
              </p>
            </div>
            <div>
              <button className="xs:mt-10 text-white rounded-full lg:text-[20px] xs:text-[16px] text-[14px] font-bold leading-[23.7px] xs:w-[180px] w-[140px] xs:h-[40px] h-[30px] lg:w-[230px] lg:h-[55px] font-poppins bg-primary">
                Book Demo
              </button>
            </div>
          </div>
          <div className="hidden w-full gap-2 lg:flex xs:py-4 lg:gap-6 lg:w-2/5">
            {/* <div className='flex gap-4'>
              <div className='w-[15%] pt-2'> */}
            <img src={hero} alt="img1" />
            {/* </div>
              <div className='w-[78%]'>
                <p className='font-normal font-open_sans lg:text-[20px] xs:text-[16px] text-[14px] lg:leading-[27px] tracking-wider'>
                  Efficiency, accuracy,and peace of mind – that's what iVerifAI brings
                  to the table as your go-toAI-powered invoice verification system.
                </p>
              </div> */}
            {/* </div> */}
            {/* <div className='flex gap-4 lg:gap-0'>
              <div className='lg:w-[18%] w-[15%]'>
                <img src={Project2} alt='img2' />
              </div>
              <div className='w-[75%]'>
                <p className='font-normal font-open_sans xs:text-[16px] text-[14px] lg:text-[20px] lg:leading-[27px] tracking-wider'>
                  Designed specifically for B2Cshipments and third-party contractors in the shipping industry
                </p>
              </div>
            </div>
            <div className='flex gap-2 lg:gap-0'>
              <div className='lg:w-[18%] w-[18%] xs:w-[16%]'>
                <img src={Project3} alt='img3' />
              </div>
              <div className='w-[75%]'>
                <p className='font-normal font-open_sans xs:text-[16px] text-[14px] lg:text-[20px] lg:leading-[27px] tracking-wider'>
                  We streamline yourverification process, ensuring every transaction aligns flawlessly with yourrate cards
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
