import React from "react";
import styles from "../style";
import {
  Hero,
  Features,
  WhyIverifai,
  ContactUs,
  Footer,
  Vector1,
  Vector2,
  Security,
} from "../components";

const Home = ({ contactRef, scrollToContact }) => {
  return (
    <div>
      <div className={`bg-white ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <Hero />
        </div>
      </div>

      <div className={`bg-primary ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <Vector1 />
          <Vector2 />
          <Features />
          <WhyIverifai />
          <Security />
          {/* <Signup /> */}
          <div ref={contactRef}>
            <ContactUs />
          </div>
          <Footer scrollToContact={scrollToContact} />
        </div>
      </div>
    </div>
  );
};

export default Home;
