import React from "react";
import { check1, check2 } from "../assets";

function Pricing() {
  return (
    <div className={`bg-white py-6 xs:py-14`}>
      <div className="flex flex-col items-center">
        <h3 className="text-[#033469] font-bold font-poppins text-[24px] xs:text-[28px] lg:text-[37px] text-center leading-[54.47px]">
          Pricing
        </h3>
        <div className="bg-[#002B9A] p-[1px] w-28"></div>
      </div>
      <div className="px-8 py-3 text-center">
        <h4 className="text-[28px] font-bold font-open_sans">
          Want to gain the benefits of the tool?
        </h4>
        <p className="text-xl tracking-wider font-open_sans">
          Pick a plan that fits your organization perfectly and get going.
        </p>
      </div>
      <div className="px-8 py-4 sm:px-16 xs:py-20 md:px-12 lg:px-36">
        <div className="grid grid-cols-1 md:grid-cols-6 gap-7 sm:gap-12 lg:gap-7">
          <div className="flex flex-col justify-between col-span-2 bg-white md:mt-14 rounded-3xl border-[#033469] border">
            <div className="bg-[#033469] rounded-t-3xl py-8">
              <h5 className="text-[20px] xs:text-[25px] font-poppins font-semibold text-center text-white">
                Free Plan
              </h5>
            </div>
            <div className="flex flex-col px-6 py-8 gap-y-4">
              <div className="flex items-start justify-start gap-2">
                <img src={check1} alt="img2" className="mt-[2px]" />
                <p className="text-base font-light text-[#1D2127]">
                  For small business owners or startup owners or individual
                  entrepreneurs Get started immediately without any setup
                  hassle.
                </p>
              </div>
              <div className="flex items-start justify-start gap-2">
                <img src={check1} alt="img2" className="mt-[2px]" />
                <p className="text-base font-light text-[#1D2127]">
                  Pre-designed templates and rate cards
                </p>
              </div>
              <div className="flex items-start justify-start gap-2">
                <img src={check1} alt="img2" className="mt-[2px]" />
                <p className="text-base font-light text-[#1D2127]">
                  Manage up to 5000 consignments
                </p>
              </div>
              <div className="flex items-start justify-start gap-2">
                <img src={check1} alt="img2" className="mt-[2px]" />
                <p className="text-base font-light text-[#1D2127]">
                  Limited to 1 user
                </p>
              </div>
            </div>
            <div className="flex justify-center px-6 py-8">
              <button className="bg-[#F1EFFF] text-[#002B9A] rounded-lg font-medium text-base py-2 w-full">
                Choose Plan
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-between col-span-2 bg-[#033469] md:mb-14 rounded-3xl border-[#033469] border">
            <div className="py-8 bg-white rounded-t-3xl">
              <h5 className="text-[20px] xs:text-[25px] font-poppins text-[#033469] font-semibold text-center">
                Standard Plan
              </h5>
            </div>
            <div className="flex flex-col px-6 py-8 gap-y-4">
              <div className="flex items-start justify-start gap-2">
                <img src={check2} alt="img2" className="mt-[2px]" />
                <p className="text-base font-light text-white">
                  For medium-sized businesses or logistics operations expansion
                </p>
              </div>
              <div className="flex items-start justify-start gap-2">
                <img src={check2} alt="img2" className="mt-[2px]" />
                <p className="text-base font-light text-white">
                  Pre-designed templates and rate cards
                </p>
              </div>
              <div className="flex items-start justify-start gap-2">
                <img src={check2} alt="img2" className="mt-[2px]" />
                <p className="text-base font-light text-white">
                  Manage up to 5000 consignments, with an option to handle more
                  at 10 cents per consignment (bulk purchase of minimum 100
                  consignments required)
                </p>
              </div>
              <div className="flex items-start justify-start gap-2">
                <img src={check2} alt="img2" className="mt-[2px]" />
                <p className="text-base font-light text-white">Up to 5 users</p>
              </div>
            </div>
            <div className="flex justify-center px-6 py-8">
              <button className="bg-[#F1EFFF] text-[#002B9A] rounded-lg font-medium text-base py-2 w-full">
                Choose Plan
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-between col-span-2 bg-white md:mt-14 rounded-3xl border-[#033469] border">
            <div className="bg-[#033469] rounded-t-3xl py-8">
              <h5 className="text-[20px] xs:text-[25px] font-poppins font-semibold text-center text-white">
                Enterprise Plan
              </h5>
            </div>
            <div className="flex flex-col px-6 py-8 gap-y-4">
              <div className="flex items-start justify-start gap-2">
                <img src={check1} alt="img2" className="mt-[2px]" />
                <p className="text-base font-light text-[#1D2127]">
                  For large-scale business owners, If your organization is
                  large-scale or has complex logistical needs or you are looking
                  for bespoke solutions, then this one is sure to help, with
                  Customizable features
                </p>
              </div>
              <div className="flex items-start justify-start gap-2">
                <img src={check1} alt="img2" className="mt-[2px]" />
                <p className="text-base font-light text-[#1D2127]">
                  Customized templates and rate cards – Tailor the tool to fit
                  your specific needs with customized templates and rate cards.
                </p>
              </div>
              <div className="flex items-start justify-start gap-2">
                <img src={check1} alt="img2" className="mt-[2px]" />
                <p className="text-base font-light text-[#1D2127]">
                  Unlimited consignments
                </p>
              </div>
              <div className="flex items-start justify-start gap-2">
                <img src={check1} alt="img2" className="mt-[2px]" />
                <p className="text-base font-light text-[#1D2127]">
                  Unlimited users
                </p>
              </div>
              <div className="flex items-start justify-start gap-2">
                <img src={check1} alt="img2" className="mt-[2px]" />
                <p className="text-base font-light text-[#1D2127]">
                  Human-in-the-Loop for support or complex reconciliations
                </p>
              </div>
              <div className="flex items-start justify-start gap-2">
                <img src={check1} alt="img2" className="mt-[2px]" />
                <p className="text-base font-light text-[#1D2127]">
                  Guaranteed Outcomes
                </p>
              </div>
            </div>
            <div className="flex justify-center px-6 py-8">
              <button className="bg-[#F1EFFF] text-[#002B9A] rounded-lg font-medium text-base py-2 w-full">
                Choose Plan
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 px-8 py-4 text-center xs:gap-6 xs:py-8 md:px-32 lg:px-48">
        <p className="text-[20px] xs:text-[25px] font-poppins xs:tracking-wider">
          With iVerifai, you can take control of your invoicing process,
          ensuring accuracy, efficiency, and peace of mind.
        </p>
        <p className="text-[20px] xs:text-[25px] font-poppins xs:tracking-wider xs:px-10">
          Choose the plan that best fits your business needs and bring seamless
          invoice management onboard.
        </p>
      </div>
    </div>
  );
}

export default Pricing;
