export const navLinks = [
  // {
  //   id: "project",
  //   title: "Project",
  // },
  // {
  //   id: "portfolio",
  //   title: "Portfolio",
  // },
  // {
  //   id: "service",
  //   title: "Service",
  // },
  // {
  //   id: "services",
  //   title: "Services",
  // },
  // {
  //   id: "contact",
  //   title: "Contact",
  // },
  {
    id: "home",
    title: "Home",
  },
  {
    id: "solutions",
    title: "Solutions",
  },
  {
    id: "about-us",
    title: "About us",
  },
];
