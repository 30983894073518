import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import { checkIcon, contactusBg } from "../assets";
import { Success, Error } from "../constants/alerts";

// const MAX_FILE_SIZE = 50 * 1024; // 50KB limit
// const ALLOWED_FILE_TYPES = [
//   "application/pdf",
//   "image/jpeg",
//   "image/png",
//   "text/csv",
//   "application/vnd.ms-excel",
// ];

function ContactUs() {
  const form = useRef();
  // const fileInputRef = useRef(null);
  const recaptchaRef = useRef(null);

  const [showMessage, setMessage] = useState(false);
  // const [fileError, setFileError] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  // const validateFile = (file) => {
  //   // Check file size
  //   if (file?.size > MAX_FILE_SIZE) {
  //     setFileError("File size must be less than 50KB");
  //     return false;
  //   }

  //   // Check file type
  //   if (!ALLOWED_FILE_TYPES.includes(file?.type)) {
  //     setFileError("Unsupported file type");
  //     return false;
  //   }

  //   setFileError("");
  //   return true;
  // };

  // const verifyCaptcha = async (token) => {
  //   const secretKey = process.env.REACT_APP_RECAPTCHA_SECRET_KEY;
  //   const verificationUrl = `https://www.google.com/recaptcha/api/siteverify?secret=${secretKey}&response=${token}`;

  //   try {
  //     const response = await fetch(verificationUrl, { method: "POST" });
  //     const data = await response.json();
  //     return data.success;
  //   } catch (error) {
  //     console.error("Captcha verification error:", error);
  //     return false;
  //   }
  // };

  const sendEmail = async (e) => {
    e.preventDefault();

    // Reset previous errors
    setCaptchaError("");

    // Verify reCAPTCHA
    const captchaToken = recaptchaRef.current.getValue();
    if (!captchaToken) {
      setCaptchaError("Please complete the reCAPTCHA");
      return;
    }

    // Verify captcha
    // const isCaptchaValid = await verifyCaptcha(captchaToken);
    // if (!isCaptchaValid) {
    //   setCaptchaError("reCAPTCHA verification failed");
    //   return;
    // }

    // const fileInput = fileInputRef.current;

    // Validate file before sending
    // if (fileInput.files.length > 0) {
    //   const file = fileInput.files[0];
    //   if (!validateFile(file)) {
    //     return;
    //   }
    // }

    try {
      // Prepare form data
      const formData = new FormData(form.current);
      const templateParams = {
        first_name: formData.get("first_name"),
        last_name: formData.get("last_name"),
        email: formData.get("email"),
        mobile_no: formData.get("mobile_no"),
        message: formData.get("message"),
      };

      // Send email
      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        {
          publicKey: process.env.REACT_APP_USER_ID,
          // files:
          //   fileInput.files.length > 0
          //     ? [
          //         {
          //           name: fileInput.files[0].name,
          //           type: fileInput.files[0].type,
          //           data: fileInput.files[0],
          //         },
          //       ]
          //     : undefined,
        }
      );

      // Success handling
      setMessage(true);
      Success("Your application was successfully submitted.");

      // Reset form
      form.current.reset();
      if (recaptchaRef.current) {
        recaptchaRef.current.reset(); // Ensure reCAPTCHA resets
      }

      // Hide success message after 10 seconds
      setTimeout(() => {
        setMessage(false);
      }, 10000);
    } catch (error) {
      Error(`Error: ${error.text}`);
      console.error("FAILED...", error);
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2">
      <div className="col-span-1 px-8 py-10 xs:py-16 md:pl-16 md:px-0 md:pr-32 bg-primary">
        <h2 className="font-poppins font-bold text-[20px] xs:text-[25px] lg:text-[37px] text-white leading-[25px] py-4 xs:py-9">
          Contact Us
        </h2>
        <div className="border-white border-[3px] w-[48px] mb-11"></div>
        {showMessage ? (
          <div className="flex flex-col justify-center items-center text-green-600 bg-white rounded-xl p-10">
            <div className="flex justify-center items-center w-20 h-20">
              <img src={checkIcon} alt="checkIcon" />
            </div>
            <p className="text-2xl font-bold font-poppins">Thank You,</p>
            <p className="text-lg font-poppins">
              Your application was successfully submitted.
            </p>
          </div>
        ) : (
          <div>
            <form ref={form} onSubmit={sendEmail}>
              <div className="grid grid-cols-1 pb-4 xs:grid-cols-2 gap-x-6 gap-y-4">
                <input
                  type="text"
                  className="col-span-1 px-[18px] py-[13px] outline-none"
                  placeholder="First Name"
                  name="first_name"
                  required
                />
                <input
                  type="text"
                  className="col-span-1 px-[18px] py-[13px] outline-none"
                  placeholder="Last Name"
                  name="last_name"
                  required
                />
                <input
                  type="email"
                  className="col-span-1 px-[18px] py-[13px] outline-none"
                  placeholder="Email"
                  name="email"
                  required
                />
                <input
                  type="text"
                  className="col-span-1 px-[18px] py-[13px] outline-none"
                  placeholder="Mobile No."
                  name="mobile_no"
                  required
                />
              </div>
              <textarea
                rows={4}
                className="px-[18px] w-full py-[13px] outline-none"
                placeholder="Message"
                name="message"
              />
              {/* <div>
                <input
                  type="file"
                  ref={fileInputRef}
                  className="px-[18px] py-[13px] outline-none"
                  placeholder="Rate Card and Invoice"
                  name="file"
                  accept=".pdf,.jpg,.jpeg,.png,.csv,.xls"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    validateFile(file);
                  }}
                />
                {fileError && (
                  <p className="text-red-500 text-sm mt-1">{fileError}</p>
                )}
              </div> */}
              {/* reCAPTCHA Component */}
              <div className="my-4">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={() => setCaptchaError("")}
                />
                {captchaError && (
                  <p className="text-red-500 text-sm mt-1">{captchaError}</p>
                )}
              </div>

              <div className="flex justify-center pt-2 md:justify-end">
                <button
                  type="submit"
                  className="text-white border-white border-[4px] text-[16px] font-open_sans font-semibold py-2 px-16 leading-[16.59px]"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      <div className="hidden col-span-1 sm:block">
        <img src={contactusBg} alt="bgImage" className="w-full h-full" />
      </div>
    </div>
  );
}

export default ContactUs;
